import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Button, Checkbox, CircularProgress, FormControlLabel } from '@mui/material';
import Icon from '@mui/material/Icon';

import usePlaylists from '@/hooks/playlists/usePlaylists';
import useSpotifyPlaylists from '@/hooks/playlists/useSpotifyPlaylists';
import { AddPlaylistsPlaylistsModel } from '@/models/PlaylistInterface';
import PlaylistAPI from '@/network/PlaylistAPI';

import Loading from '../components/utility/Loading';
import AppContext, { SnackbarContextModel } from '../contexts/AppContext';

export default function AddPlaylistPage() {
  document.title = 'Add playlists';
  const [isAddingPlaylist, setIsAddingPlaylist] = useState<boolean>(false);
  const [selectedPlaylists, setSelectedPlaylists] = useState<AddPlaylistsPlaylistsModel[]>([]);
  const { unconnectedSpotifyPlaylists, spotifyPlaylistsError, spotifyPlaylistsIsLoading, refetchSpotifyPlaylists } =
    useSpotifyPlaylists();
  const { refetchPlaylists } = usePlaylists({
    pageNumber: 1,
    status: '',
    sortBy: 'pending-pitches',
    orderBy: 'desc',
  });

  const { dispatchSnackbar } = useContext<SnackbarContextModel>(AppContext);

  const navigate = useNavigate();

  useEffect(() => {
    if (spotifyPlaylistsError) {
      dispatchSnackbar({
        type: 'OPEN_SNACKBAR',
        payload: {
          message: 'An error occurred while fetching your playlists',
          type: 'error',
        },
      });
    }
  }, [dispatchSnackbar, spotifyPlaylistsError]);

  const handleCheckboxChange = (event: AddPlaylistsPlaylistsModel) => {
    const result = selectedPlaylists.filter((item: AddPlaylistsPlaylistsModel) => item === event);
    if (result.length > 0) {
      const checked = selectedPlaylists.filter((item: AddPlaylistsPlaylistsModel) => item !== event);
      setSelectedPlaylists(checked);
    } else {
      setSelectedPlaylists([...selectedPlaylists, event]);
    }
  };

  const submitPlaylists = useCallback(() => {
    setIsAddingPlaylist(true);
    if (selectedPlaylists.length > 0) {
      const data = {
        playlistsToConnect: selectedPlaylists,
      };
      PlaylistAPI.addPlaylists(data)
        .then(() => {
          navigate(-1);
          dispatchSnackbar({
            type: 'OPEN_SNACKBAR',
            payload: {
              message: 'Your playlists have been added successfully',
              type: 'success',
            },
          });
          refetchSpotifyPlaylists();
          setIsAddingPlaylist(false);
          refetchPlaylists();
        })
        .catch((err) => {
          if (err.response.data.statusCode === 400) {
            dispatchSnackbar({
              type: 'OPEN_SNACKBAR',
              payload: {
                message: err.response.data.errorMessage,
                type: 'error',
              },
            });
            setIsAddingPlaylist(false);
          }
        });
    }
  }, [dispatchSnackbar, navigate, refetchPlaylists, refetchSpotifyPlaylists, selectedPlaylists]);

  const isChecked = (event: AddPlaylistsPlaylistsModel) => {
    const result = selectedPlaylists.filter((item: AddPlaylistsPlaylistsModel) => item === event);
    return result.length > 0;
  };

  return (
    <div className="onboarding-page">
      <h1>Add a new playlist</h1>
      <h3 className="text-faded">
        Choose your playlists from your Spotify account that you wish to connect to un:hurd music. Once you have
        connected these playlists, you can receive pitches from different artists.
      </h3>
      <Button
        className="icon-btn add-playlist-close-btn"
        onClick={() => {
          navigate(-1);
        }}
        data-testid="close-add-new-playlists-button"
      >
        <Icon>close</Icon>
      </Button>
      {!spotifyPlaylistsIsLoading ? (
        <div className="playlists-container mt32">
          {unconnectedSpotifyPlaylists?.map((item: AddPlaylistsPlaylistsModel, index) => (
            <div
              key={item.spotifyId}
              className="playlist-item cursor-pointer"
              style={{ '--animation-number': `${index}` } as React.CSSProperties}
              onClick={() => {
                handleCheckboxChange(item);
              }}
            >
              <div className="playlist-item-checkbox-container">
                <FormControlLabel
                  label=""
                  control={
                    <Checkbox
                      id="playlist-checkbox"
                      name="playlist-checkbox"
                      checked={isChecked(item)}
                      icon={<div className="checkbox-icon"></div>}
                      checkedIcon={
                        <div className="checkbox-icon">
                          <div className="checked"></div>
                        </div>
                      }
                      onChange={() => {
                        handleCheckboxChange(item);
                      }}
                      value={item.id}
                    />
                  }
                />
              </div>
              <img src={item.image?.url ? item.image.url : '/images/logos/no-image-available.svg'} alt="playlist-img" />
              <div className="playlist-titles">
                <div className={`title ${item.isConnected ? 'text-blue-gradient' : ''}`}>
                  {item.name ? item.name : 'No title'}
                </div>
                <div className="followers text-faded">Tracks: {item.totalTracks}</div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className="centered-loading mt48">
          <Loading />
        </div>
      )}
      {!spotifyPlaylistsIsLoading && unconnectedSpotifyPlaylists && unconnectedSpotifyPlaylists.length === 0 && (
        <div className="text-center title-card mt16">
          <h4 data-testid="no-playlists-to-connect">You don't have any playlists to connect</h4>
        </div>
      )}
      <Button
        disabled={selectedPlaylists.length === 0 || isAddingPlaylist}
        className="btn-white continue-btn"
        onClick={submitPlaylists}
        data-testid="add-playlists-continue-button"
      >
        {isAddingPlaylist ? (
          <CircularProgress size={16} />
        ) : (
          <>
            <span className="btn-text icon-suffix">Continue</span>
            <Icon>chevron_right</Icon>
          </>
        )}
      </Button>
    </div>
  );
}
